<template>

  <div class="main">
    <v-dialog v-model="dialogEditar" width="1000">
      <v-card>
        <v-card-title>
          Alteração de Propostas
          <v-spacer/>
          <v-btn class="mx-2" elevation="0" color="error" @click="cancelar()">Cancelar</v-btn>
          <v-btn elevation="0" color="primary" @click="editar()">Salvar</v-btn>
        </v-card-title>
        <v-divider class="my-4"></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="1.5">
              <v-text-field v-model.number="propostaAtualizar.idproposta" type="number" outlined dense
                            label="Cód. Proposta" class="mb-n5" @change="get()"></v-text-field>
            </v-col>

            <v-col cols="12" md="1.5">
              <v-select v-bind:disabled="estaDesabilitado" v-model.number="propostaAtualizar.idservico"
                        :items="servicos" item-text="servico" item-value="idservico" outlined dense
                        label="Cód. Serviço" class="mb-n5"></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-select v-bind:disabled="estaDesabilitado" v-model.number="propostaAtualizar.idempresa"
                        :items="empresasTitulo" item-text="filial" item-value="idfilial" outlined dense
                        label="Cód. Empresa" class="mb-n5"></v-select>
            </v-col>

            <v-col cols="12" md="2">
              <v-text-field v-bind:disabled="estaDesabilitado" v-model.number="propostaAtualizar.idvendedor"
                            type="number" outlined dense label="Cód. Vendedor" class="mb-n5"></v-text-field>
            </v-col>

            <v-col cols="12" md="2">
              <InputDatePicker v-bind:disabled="estaDesabilitado" v-model="propostaAtualizar.dtproposta"
                               label="Data Proposta" :outlined="true" :dense="true"/>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field v-model.number="propostaAtualizar.valproposta" type="number" outlined dense
                            label="Valor da proposta (R$)" class="mb-n5"></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-select v-model="propostaAtualizar.statusproposta" :items="status" required outlined dense
                        label="Status da proposta" class="mb-n5"></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogErro.status" persistent>
      <CardDialog :dialog="dialogErro"/>
    </v-dialog>

    <v-card>
      <v-card-title class="primary--text">
        Cadastro de propostas
        <v-spacer/>
        <v-btn class="mx-2" elevation="0" color="green" @click="dialogEditar = true, editandoProposta=true">Editar
        </v-btn>
        <v-btn elevation="0" color="primary" @click="cadastrar()">Cadastrar</v-btn>
      </v-card-title>
      <v-divider/>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="1.5">
            <v-text-field v-model.number="proposta.idproposta" type="number" outlined dense
                          label="Cód. Proposta" class="mb-n5"></v-text-field>
          </v-col>

          <v-col cols="12" md="1.5">
            <v-select v-bind:disabled="estaDesabilitado" v-model.number="proposta.idservico"
                      :items="servicos" item-text="servico" item-value="idservico" outlined dense
                      label="Cód. Serviço" class="mb-n5"></v-select>
          </v-col>

          <v-col cols="12" md="2">
            <v-select v-bind:disabled="estaDesabilitado" v-model.number="proposta.idempresa"
                      :items="empresasTitulo" item-text="filial" item-value="idfilial" outlined dense
                      label="Cód. Empresa" class="mb-n5"></v-select>
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-bind:disabled="estaDesabilitado" v-model.number="proposta.idvendedor"
                          type="number" outlined dense label="Cód. Vendedor" class="mb-n5"></v-text-field>
          </v-col>

          <v-col cols="12" md="2">
            <InputDatePicker v-bind:disabled="estaDesabilitado" v-model="proposta.dtproposta"
                             label="Data Proposta" :outlined="true" :dense="true"/>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field v-model.number="proposta.valproposta" type="number" outlined dense
                          label="Valor da proposta (R$)" class="mb-n5"></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-select v-model="proposta.statusproposta" :items="status" required outlined dense
                      label="Status da proposta" class="mb-n5"></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="primary white--text elevation-0 my-3" dark>
      <v-card-title>
        <v-span>Propostas Cadastradas</v-span>
      </v-card-title>
      <v-card class="primary white--text elevation-0 pt-0">
        <v-row class="pa-0">
          <v-col class="d-flex justify-space-around">
            <v-col>
              <v-select v-model.number="busca.idempresa"
                        :items="empresasTitulo" item-text="filial" item-value="idfilial" outlined dense
                        label="Cód. Empresa" class="mb-n5"></v-select>
            </v-col>
            <v-col>
              <v-select v-model.number="busca.idservico"
                        :items="servicos" item-text="servico" item-value="idservico" outlined dense
                        label="Cód. Serviço" class="mb-n5"></v-select>
            </v-col>
            <v-col>
              <v-select label="Período" outlined dense :items="periodos" item-text="data" item-value="data"
                        v-model="busca.dtini" class="mb-n5"></v-select>
            </v-col>
            <v-col>
              <v-btn :disabled="carregando" class="text--primary" color="white" elevation="0"
                     @click="listarPropostas(0)">
                Buscar propostas
              </v-btn>
            </v-col>
            <v-col>
              <v-btn :disabled="carregando" class="text--primary" color="white" elevation="0"
                     @click="limpaFiltros">
                Limpar Filtros
              </v-btn>
            </v-col>
          </v-col>
        </v-row>
      </v-card>

      <v-card-text>
        <v-simple-table dense light>
          <thead>
          <tr>
            <th class="text-center">Cód. Proposta</th>
            <th class="text-center">Cód. Empresa</th>
            <th class="text-center">Cód. Serviço</th>
            <th class="text-center text-truncate">Valor Proposta</th>
            <th class="text-center text-truncate">Data Proposta</th>
            <th class="text-center text-truncate">Cód. Vendedor</th>
            <th class="text-center text-truncate">Status Proposta</th>
          </tr>
          </thead>
          <tbody>
          <template v-if="dados.total > 0">
            <tr v-for="(p, i) in propostas.lista" :key="i">
              <td class="text-center">{{ p.idproposta }}</td>
              <td class="text-center">{{ p.idempresa }}</td>
              <td class="text-center">{{ p.idservico }}</td>
              <td class="text-center text-uppercase">{{
                  p.valproposta.toFixed(2) |
                      formataDinheiro
                }}
              </td>
              <td class="text-center text-uppercase">{{ p.dtproposta | formataData }}</td>
              <td class="text-center text-uppercase">{{ p.idvendedor }}</td>
              <td class="text-center text-uppercase">{{ p.statusproposta }}</td>
            </tr>
          </template>
          <template v-else>
            <tr>
              <td class="text-center" colspan="7">Nenhum registro encontrado.</td>
            </tr>
          </template>
          </tbody>
        </v-simple-table>
        <v-divider v-if="dados.total > 20"/>
        <v-progress-linear indeterminate absolute :active="carregando"/>
        <Paginacao :class="dados.total > 20 ? 'my-2 pb-2' : ''" :total="dados.total"
                   @atualizar="listarPropostas" :carregando="carregando" :pg="pgSelecionada"/>

      </v-card-text>


    </v-card>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDialog from "../Widgets/CardDialog";
import Paginacao from "../Widgets/Paginacao.vue";
export default {
    components: { Paginacao, CardDialog, InputDatePicker },
    data: () => ({
        dialog: false,
        dialogErro: {
            status: false,
            title: "",
            msg: "",
            icon: "",
            iconColor: "",
        },
        sheet: false,
        carregando: false,
        carregandoSkeleton: true,
        carregandoPropostas: true,
        dados: { total: 0 },
        periodos: [],
        nomeBotao: 'Cadastrar',
        editandoProposta: false,
        propostaAtualizar: [],
        dialogEditar: false,
        estaDesabilitado: false,
        totais: {},
        busca: {
            status: null,
            idservico: null,
            idempresa: null,
            dtini: ''
        },
        propostas: [],
        proposta: [],
        status: ['1 - Aprovado', '2 - Negado', '3 - Aguardando'],
        empresasTitulo: [
            { "filial": "Ivaiporã", "idfilial": 1 },
            { "filial": "Pitanga", "idfilial": 2 },
            { "filial": "São João do Ivaí", "idfilial": 3 },
            { "filial": "Castelo", "idfilial": 4 },
            { "filial": "Candido", "idfilial": 5 },
            { "filial": "Apucarana", "idfilial": 7 },
            { "filial": "Jardim Alegre", "idfilial": 8 },
            { "filial": "Manoel Ribas", "idfilial": 9 },
            { "filial": "Faxinal", "idfilial": 10 },
            { "filial": "Campo Mourão", "idfilial": 11 },
            { "filial": "Cianorte", "idfilial": 14 },
            { "filial": "Paranavaí", "idfilial": 15 },
            { "filial": "Telêmaco", "idfilial": 16 },
            { "filial": "Roncador", "idfilial": 17 },
            { "filial": "Guarapuava", "idfilial": 18 },
            { "filial": "Palmital", "idfilial": 19 },
            { "filial": "Ibiporã", "idfilial": 20 },
            { "filial": "Turvo", "idfilial": 21 },
            { "filial": "São Mateus do Sul", "idfilial": 23 },
            { "filial": "São João do Triunfo", "idfilial": 24 },
            { "filial": "Pontal do Paraná", "idfilial": 26 },
            { "filial": "Mandaguari", "idfilial": 27 },
            { "filial": "Astorga", "idfilial": 28 },
            { "filial": "Cambé", "idfilial": 30 },
            { "filial": "Reserva", "idfilial": 31 },
            { "filial": "Rolândia", "idfilial": 35 },
            { "filial": "Maringá", "idfilial": 36 },
            { "filial": "Laranjeiras", "idfilial": 38 },
            { "filial": "E-commerce - CD", "idfilial": 39 }
        ],
        servicos: [
            { "servico": "1 - Empréstimo Pessoal", "idservico": 1 },
            { "servico": "21 - Consignado", "idservico": 21 },
            { "servico": "22 - Crédito Rural", "idservico": 22 },
            { "servico": "23 - FGTS", "idservico": 23 }
        ],
        pgLimit: 10,
        pgSelecionada: 0,
    }),
    computed: {
        ...mapState(["backendUrl", "pgLimit", "usuario"]),
    },
  methods: {
    get() {
      if (!this.propostaAtualizar.idproposta) {
        this.dialogErro.title = "Erro";
        this.dialogErro.msg = "Código identificador não informado!";
        this.dialogErro.icon = "mdi-alert-circle-outline";
        this.dialogErro.iconColor = "red";
        this.dialogErro.status = true;
        return;
      }
      this.erro = null;
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}propostas/cadastro/propostaget`, {
            idproposta: this.propostaAtualizar.idproposta || null
          })
          .then((res) => {
            this.propostaAtualizar = {};
            this.propostaAtualizar = res.data;
            if (this.propostaAtualizar.idusuarioalteracao != 0) {
              this.dialogErro.title = "Alerta!";
              this.dialogErro.msg = "Esta proposta já foi atualizada!";
              this.dialogErro.icon = "mdi-alert-circle-outline";
              this.dialogErro.iconColor = "orange";
              this.dialogErro.status = true;
            }
            this.nomeBotao = 'Editar';
            this.editandoProposta = true;
            this.estaDesabilitado = true;
            this.carregando = false;
          })
          .catch(() => {
            this.propostaAtualizar.idproposta = 0;
            this.dialogErro.title = "Erro";
            this.dialogErro.msg = "Proposta não encontrada!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
            this.carregando = false;
          });
    },
    cancelar() {
      this.propostaAtualizar = {
        dtproposta: ''
      };
      this.estaDesabilitado = false;
      this.editandoProposta = false;
      this.dialogEditar = false;
    },
    limpaFiltros() {
      this.busca = [];
      this.listarPropostas(0);
    },
    listarPeriodos() {
      return axios
          .post(`${this.backendUrl}servicos/periodo/listar`)
          .then((res) => {
            this.periodos = res.data;
          });
    },
    listarPropostas(pg) {
      this.carregando = true;
      this.pgSelecionada = pg;
      return axios
          .post(`${this.backendUrl}propostas/listar`, {
            offset: this.pgLimit * pg,
            idempresa: this.busca.idempresa || null,
            idservico: this.busca.idservico || null,
            dtproposta: this.busca.dtini || null,

          })
          .then((res) => {
            this.dados = res.data;
            this.propostas = res.data;
            this.carregandoPropostas = false;
            this.carregando = false;
            this.carregandoSkeleton = false;
          })
          .catch(() => {
            this.carregando = false;
            this.dialogErro.title = "Erro";
            this.dialogErro.msg =
                "Não foi possível listar as propostas!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
          });
    },
    cadastrar() {
      if (
          !this.proposta.idproposta ||
          !this.proposta.idservico ||
          !this.proposta.idempresa ||
          !this.proposta.dtproposta ||
          !this.proposta.valproposta ||
          !this.proposta.statusproposta
      ) {
        this.dialogErro.title = "Erro";
        this.dialogErro.msg =
            "Um ou mais campos não foram preenchidos!";
        this.dialogErro.icon = "mdi-alert-circle-outline";
        this.dialogErro.iconColor = "red";
        this.dialogErro.status = true;
        return;
      }
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}propostas/cadastro/proposta`, {
            ...this.proposta,
          })
          .then((res) => {
            //this.carregando = false;
            if (res.data == true) {
              this.dialogErro.title = "Sucesso";
              this.dialogErro.msg =
                  "Proposta cadastrada com sucesso!";
              this.dialogErro.icon = "mdi-check-circle-outline";
              this.dialogErro.iconColor = "success";
              this.dialogErro.status = true;
              this.proposta = {};
              this.proposta.dtproposta = null;
              this.listarPropostas();
            } else {
              this.dialogErro.title = "Erro";
              this.dialogErro.msg = "Erro ao cadastrar proposta!";
              this.dialogErro.icon = "mdi-alert-circle-outline";
              this.dialogErro.iconColor = "red";
              this.dialogErro.status = true;
            }
          })
          .catch(() => {
            this.dialogErro.title = "Erro";
            this.dialogErro.msg = "Erro ao cadastrar proposta!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
            this.carregando = false;
          });
    },
    editar() {
      if (
          !this.propostaAtualizar.valproposta ||
          !this.propostaAtualizar.statusproposta
      ) {
        this.dialogErro.title = "Erro";
        this.dialogErro.msg =
            "Um ou mais campos não foram preenchidos!";
        this.dialogErro.icon = "mdi-alert-circle-outline";
        this.dialogErro.iconColor = "red";
        this.dialogErro.status = true;
        return;
      }
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}propostas/editar`, {
            ...this.propostaAtualizar,
          })
          .then((res) => {
            //this.carregando = false;
            if (res.data == true) {
              this.dialogErro.title = "Sucesso";
              this.dialogErro.msg =
                  "Proposta alterada com sucesso!";
              this.dialogErro.icon = "mdi-check-circle-outline";
              this.dialogErro.iconColor = "success";
              this.dialogErro.status = true;
              this.propostaAtualizar = {};
              this.propostaAtualizar.dtproposta = null;
              this.listarPropostas();
              this.cancelar();
            } else {
              this.dialogErro.title = "Erro";
              this.dialogErro.msg = "Erro ao alterar proposta!";
              this.dialogErro.icon = "mdi-alert-circle-outline";
              this.dialogErro.iconColor = "red";
              this.dialogErro.status = true;
              this.propostaAtualizar = {};
            }
          })
          .catch(() => {
            this.dialogErro.title = "Erro";
            this.dialogErro.msg = "Erro ao alterar proposta!";
            this.dialogErro.icon = "mdi-alert-circle-outline";
            this.dialogErro.iconColor = "red";
            this.dialogErro.status = true;
            this.propostaAtualizar = {};
            this.carregando = false;
            this.cancelar();
          });

    },

    async init() {
      this.busca.idfilial = this.usuario.idfilial;
      await this.listarPropostas(0);
      await this.listarPeriodos();
    },
  },
    created() {
        this.init();
    },
};
</script>

<style scoped>

</style>